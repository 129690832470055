const React = require("react");

const activateWDDR = false;

exports.onClientEntry = () => {
	if (process.env.GATSBY_ACTIVE_ENV === "development" && activateWDDR) {
		const whyDidYouRender = require("@welldone-software/why-did-you-render");
		whyDidYouRender(React, {
			trackAllPureComponents: true,
			trackHooks: true,
			include: [/Page/],
		});
	}
};

exports.onRouteUpdate = ({ location }) => {
	if (location.hash) {
		const targetElement = document.querySelector(location.hash);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: "smooth" });
		}
	} else {
		window.scrollTo(0, 0);
	}
};
